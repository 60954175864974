export default () => ({
  cookiePolicyBannerAccepted: null,
  bannerEl: null,
  footerEl: null,

  init() {
    this.store = Alpine.store("data")
    this.bannerEl = document.querySelector("[data-cookie-banner]")
    this.footerEl = document.querySelector("[data-footer-container]")

    this.cookiePolicyBannerAccepted = this.getStoredConsent()
    this.store.values.cookiePolicyBannerAccepted = this.cookiePolicyBannerAccepted

    if (this.cookiePolicyBannerAccepted === null) {
      this.showBanner({ shouldScrollToTop: false })
    }
  },

  getStoredConsent() {
    const storedConsent = window.localStorage.getItem("cookiePolicyBannerAccepted")

    if (storedConsent === "true") return true
    if (storedConsent === "false") return false

    return null
  },

  accept() {
    this.updateConsentState(true)
    window.allConsentGranted?.()
    this.hideBanner()
  },

  reject() {
    this.updateConsentState(false)
    window.allConsentRejected?.()
    this.hideBanner()
  },

  updateConsentState(isAccepted) {
    this.cookiePolicyBannerAccepted = isAccepted
    window.localStorage.setItem("cookiePolicyBannerAccepted", String(isAccepted))
    this.store.values.cookiePolicyBannerAccepted = isAccepted
  },

  showBanner({ shouldScrollToTop = false } = {}) {
    if (!this.bannerEl) return

    this.bannerEl.style.display = "flex"

    this.$nextTick(() => {
      if (window.innerWidth >= 768 && shouldScrollToTop) {
        window.scrollTo({ top: 0, behavior: "smooth" })
      }
    })
  },

  hideBanner() {
    if (!this.bannerEl) return

    this.bannerEl.style.display = "none"
  },
})
