import primer from "../primer"
import upsell from "../upsell"
import exitIntentPopup from "../exitIntentPopup"

export default (getView) =>
  ({ nextStepPath, failedPaymentPath, exitIntentPath, upsellItem }) => ({
    ...getView(nextStepPath),
    ...upsell(upsellItem),
    ...primer(),
    ...exitIntentPopup(exitIntentPath),

    paymentMethods: [],
    offers: [],
    activeOffer: null,
    showSpinner: false,
    showFetchError: false,
    paymentError: false,
    error: null,
    statusToTrack: "VISIT_CHECKOUT",

    init() {
      const searchParams = new URLSearchParams(window.location.search)
      const primerErrorSearchParam = searchParams.get("primer_error")

      this.initExitIntentPopup()

      if (primerErrorSearchParam) {
        this.error =
          primerTranslations.errors[primerErrorSearchParam] || primerTranslations.errors.default
        // requestAnimationFrame(() => this.scrollToPrimer())
        this.paymentError = true
      }

      this.store.trackViewAndMaybeStatus(this.statusToTrack)

      this.activeOffer = this.store.values.activeOffer

      const isBasicCheckout = upsellItem === "basic"

      // For Basic checkout
      if (isBasicCheckout) {
        this.initPrimer({
          checkoutCompletePath: nextStepPath,
          checkoutFailedPath: failedPaymentPath,
        })

        return
      }

      this.initUpsell()
      this.initPrimer({
        checkoutCompletePath: this.getThankYouPath(),
        checkoutFailedPath: this.getFailedCheckoutPath(),
      })

      this.commonInit()
    },

    // CF d-series code

    async backButtonHandler() {
      const { values } = this.store
      
      if (this.store.shouldShowExitIntentPopup()) {
        const token = values.token
        const tokenPath = token ? `${exitIntentPath}#stored-token=${token}` : popupStepPath

        window.location.replace(tokenPath)
      } else {
        history.back()
      }
    },

    getDiscountKey(isTrialOffer, isExitIntent) {
      if (isTrialOffer && isExitIntent) return "trial_chance_price_discount"
      if (isTrialOffer) return "trial_price_discount"
      if (isExitIntent) return "chance_discount"

      return "discount"
    },

    getFormattedDiscountPercentage() {
      const { exitIntent } = this.store.values
      const isTrialOffer = this.store.isOfferTrial(this.activeOffer)
      const isBasicOffer = this.store.isBasicOffer(this.activeOffer)
      const discountKey = this.getDiscountKey(isTrialOffer, exitIntent)

      if (!isBasicOffer) {
        return this.activeOffer.customData.discount || null
      }

      return this.activeOffer.customData[discountKey]
    },

    getFormattedDiscount() {
      const { exitIntent } = this.store.values
      const isTrial = this.store.isOfferTrial(this.activeOffer)
      const isBasicOffer = this.store.isBasicOffer(this.activeOffer)
      const {
        chance_discount_amount = 0,
        trial_price_discount_amount = 0,
        trial_chance_price_discount_amount = 0,
        total_price_discount = 0,
      } = this.activeOffer.customData

      if (!isBasicOffer) return this.store.getFormattedPrice(total_price_discount)
      if (isTrial && exitIntent)
        return this.store.getFormattedPrice(trial_chance_price_discount_amount)
      if (isTrial) return this.store.getFormattedPrice(trial_price_discount_amount)
      if (exitIntent) return this.store.getFormattedPrice(chance_discount_amount)

      return this.store.getFormattedPrice(total_price_discount)
    },

    isNutritionistBubbleNeeded(upsellItem) {
      const bubbleNeededKeys = ["premium", "chat", "shopping_list", "shopping_list_and_premium"]

      return bubbleNeededKeys.includes(upsellItem)
    },

    scrollToPrimer() {
      document
        .getElementById("primer")
        .scrollIntoView({ behavior: "smooth", block: "start", inline: "center" })
    },
  })
