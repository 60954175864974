import {
  getBmi,
  getDaysTillGoalDate,
  getFasterProgressDaysDiff,
  getLocalizedDateLabel,
} from "../helpers"
import exitIntentPopup from "../exitIntentPopup"
import { PRICE_TYPES } from "../dataStore"

const ABANDONED_CART_PATH = "abandoned-basket-plan-selection"

export default (getView) =>
  ({ nextStepPath, exitIntentPath, activeStepPath }) => ({
    ...getView(nextStepPath),
    ...exitIntentPopup(exitIntentPath),

    paymentMethods: [],
    offers: [],
    activeOffer: null,
    showFetchError: false,
    paymentError: false,
    isSpinnerActive: false,

    init() {
      window.addEventListener("pageshow", (event) => {
        if (event.persisted) {
          this.isSpinnerActive = false
        }
      })

      this.commonInit()

      if (this.shouldInitializeAbandonedCartState()) {
        this.initializeAbandonedCartState()
      }

      const basicOffers = pipelineConfig.offers.filter((offer) => this.store.isBasicOffer(offer))
      const offers = basicOffers.sort((a, b) => a.customData.sequence - b.customData.sequence)
      const storedOffer = this.store.values.activeOffer
      const selectedOffer =
        storedOffer || offers.find((offer) => offer.customData.default_select === "true")

      this.offers = offers
      this.activeOffer = selectedOffer || offers[0]

      this.initExitIntentPopup()
    },

    // Helper functions
    isMetric() {
      return this.store.values.measurementSystem === "metric"
    },

    getDiscountKey(isTrial, exitIntent) {
      if (isTrial && exitIntent) {
        return "trial_chance_price_discount"
      }
      if (isTrial) {
        return "trial_price_discount"
      }
      if (exitIntent) {
        return "chance_discount"
      }
      return "discount"
    },

    getPeriodPriceKey(isTrial, exitIntent) {
      if (isTrial && exitIntent) {
        return "trial_chance_price_per_period"
      }
      if (isTrial) {
        return "trial_price_per_period"
      }
      if (exitIntent) {
        return "period_price_chance"
      }
      return "period_price"
    },

    // BMI calculations
    getBmi() {
      const isMetric = this.isMetric()
      return getBmi(this.store.values, isMetric).toFixed(2)
    },

    getTargetBmi() {
      const { heightCm, heightFt, heightIn, targetWeightKg, targetWeightLbs } = this.store.values

      const values = {
        heightCm,
        heightFt,
        heightIn,
        weightKg: targetWeightKg,
        weightLbs: targetWeightLbs,
      }

      const isMetric = this.isMetric()
      return getBmi(values, isMetric).toFixed(2)
    },

    getGoalDateLabel() {
      const isMetric = this.isMetric()
      const { weightKg, weightLbs, targetWeightKg, targetWeightLbs, goalPaceKg, goalPaceLbs } =
        this.store.values

      const daysTillGoalDate = getDaysTillGoalDate(
        {
          weightKg,
          weightLbs,
          targetWeightKg,
          targetWeightLbs,
          goalPaceKg,
          goalPaceLbs,
        },
        isMetric
      )

      const fasterProgressDaysDiff = getFasterProgressDaysDiff(this.store.values, isMetric)

      const goalDate = new Date()
      goalDate.setDate(goalDate.getDate() + daysTillGoalDate - fasterProgressDaysDiff)

      return getLocalizedDateLabel(goalDate, true)
    },

    isOfferActive(offer) {
      return offer.brandOfferId === this.activeOffer.brandOfferId
    },

    isBestDeal(offer) {
      const exitIntent = this.store.values.exitIntent ?? false; // Default to false if not set
    
      if (this.isOfferActive(offer)) {
        this.store.setActiveOffer(offer);
      }
    
      // Get the period price key and price for the current offer
      const periodPriceKey = this.getPeriodPriceKey(this.store.isOfferTrial(offer), exitIntent);
      const periodPrice = parseFloat(offer.customData[periodPriceKey]);
    
      if (isNaN(periodPrice)) {
        return false;
      }
    
      // Gather period prices for all offers based on the correct keys
      const allOffersPeriodPrices = this.offers
        .map((o) => {
          const otherPeriodPriceKey = this.getPeriodPriceKey(this.store.isOfferTrial(o), exitIntent);
          const otherPeriodPrice = parseFloat(o.customData[otherPeriodPriceKey]);
          return isNaN(otherPeriodPrice) ? null : otherPeriodPrice;
        })
        .filter((price) => price !== null);
    
      const minPrice = Math.min(...allOffersPeriodPrices);
    
      // Check if the current offer has the minimum price and is unique
      return (
        periodPrice === minPrice &&
        allOffersPeriodPrices.indexOf(periodPrice) === allOffersPeriodPrices.lastIndexOf(periodPrice)
      );
    },    


    getFormattedDiscountPercentage(activeOffer = this.activeOffer) {
      const { exitIntent } = this.store.values
      const isTrial = this.store.isOfferTrial(activeOffer)
      const discountKey = this.getDiscountKey(isTrial, exitIntent)

      return activeOffer.customData[discountKey]
    },

    getFormattedDiscount(activeOffer) {
      const { discount_amount = 0 } = activeOffer.customData

      if (!discount_amount) {
        return ""
      }

      return this.store.getFormattedPrice(discount_amount)
    },

    getPriceType(offer) {
      if (!this.store.isBasicOffer(offer)) return PRICE_TYPES.STANDARD

      const isTrialOffer = this.store.isOfferTrial(offer)
      const { exitIntent } = this.store.values

      if (isTrialOffer && exitIntent) return PRICE_TYPES.CHANCE_TRIAL
      if (isTrialOffer) return PRICE_TYPES.TRIAL
      if (exitIntent) return PRICE_TYPES.CHANCE

      return PRICE_TYPES.STANDARD
    },

    getPricePerPeriod(offer) {
      const priceType = this.getPriceType(offer)
      const periodPriceKey = this.getPeriodPriceKey(
        this.store.isOfferTrial(offer),
        this.store.values.exitIntent
      )

      return this.store.getFormattedPrice(
        offer.customData[periodPriceKey] || offer.customData[priceType]
      )
    },

    // Abandoned cart state initialization
    shouldInitializeAbandonedCartState() {
      return (
        activeStepPath.includes(ABANDONED_CART_PATH) && !this.store.values.isAbandonedCartViewed
      )
    },

    async initializeAbandonedCartState() {
      Object.assign(this.store.values, {
        isAbandonedCartViewed: true,
        exitIntent: false,
        showDiscountModal: false,
      })

      await this.store.push()
    },

    // UI interactions
    scrollToElement(id) {
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({ behavior: "smooth" })
      }
    },

    selectOffer(offer) {
      this.activeOffer = offer
    },

    // Tracking and updates
    async completePayment() {
      this.store.values.completedAt = new Date()
      await this.store.push()
    },

    async trackVisitCheckout() {
      this.store.values.status = "VISIT_CHECKOUT"
      await this.store.push()
    },

    async updateOrderUtms() {
      await this.store.push()
    },

    isNutritionistBubbleNeeded(translationKey) {
      const bubbleNeededKeys = [
        "paymentPremium",
        "paymentChat",
        "paymentShoppinglist",
        "paymentBundle",
      ]
      return bubbleNeededKeys.includes(translationKey)
    },

    async submitOffer() {
      this.isSpinnerActive = true

      try {
        await this.store.setActiveOffer(this.activeOffer)

        window.location = nextStepPath
      } catch (error) {
        console.error("Error submitting offer:", error)
      } finally {
        this.isSpinnerActive = false
      }
    },
  })
