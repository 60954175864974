import { getBmi, getNormalizedHeight, getNormalizedWeight } from "../helpers"

const KGS_IN_LBS = 0.453592
const LBS_IN_KG = 2.20462

export const kgToLbs = (kg = 0) => Math.round(kg / KGS_IN_LBS)
export const lbsToKg = (lbs = 0) => Math.round(lbs / LBS_IN_KG)

export default (getView) =>
  ({ nextStepPath, formFields }) => ({
    bmi: null,
    suggestedWeightKg: null,
    suggestedWeightLbs: null,
    minSuggestedWeightKg: null,
    minTargetWeighError: false,
    maxTargetWeighError: false,

    ...getView({
      nextStepPath,
      formFields,
    }),

    init() {
      this.commonInit()
      this.bmi = this.getBmi().toFixed(1)

      // Get and set suggested weight values
      const { suggestedWeightKg, suggestedWeightLbs } = this.getSuggestedWeight()
      this.suggestedWeightKg = suggestedWeightKg
      this.suggestedWeightLbs = suggestedWeightLbs

      // Update form data and init UI input
      this.formValues.targetWeightKg = this.suggestedWeightKg
      this.formValues.targetWeightLbs = this.suggestedWeightLbs

      this.setSubmitAllowed(this.formValues, formFields)
    },

    handleUnitsChange(units) {
      this.setMeasurementSystem(units)
      this.convertWeight()
      this.setSubmitAllowed(this.formValues, formFields)
    },

    handleWeightInput($event, field) {
      $event.target.value = $event.target.value.replace(/[^\d]/g, "")
      this.formValues[field] = $event.target.value && Number($event.target.value)
      this.errors[field] = null

      this.convertWeight()
      this.resetErrors()

      this.setSubmitAllowed(this.formValues, formFields)
    },

    convertWeight() {
      if (this.isMetric()) {
        // Don't do anything if there is nothing to convert
        // to prevent invalid values to appear
        if (!this.formValues.targetWeightKg && this.formValues.targetWeightKg !== 0) return

        this.formValues.targetWeightLbs = kgToLbs(this.formValues.targetWeightKg)
      }

      if (this.isImperial()) {
        if (!this.formValues.targetWeightLbs && this.formValues.targetWeightLbs !== 0) return

        this.formValues.targetWeightKg = lbsToKg(this.formValues.targetWeightLbs)
      }
    },

    // Perform final convertions before submit
    processFormValues() {
      const { targetWeightLbs, targetWeightKg } = this.formValues

      if (this.isMetric()) {
        return {
          targetWeightKg,
          targetWeightLbs: kgToLbs(targetWeightKg),
        }
      }

      if (this.isImperial()) {
        return {
          targetWeightKg: lbsToKg(targetWeightLbs),
          targetWeightLbs,
        }
      }
    },

    setSubmitAllowed(formValues, formFields) {
      this.validateRequired(formValues, formFields)

      this.validateWeightRange()
    },

    validateRequired(formValues, formFields) {
      const requiredFields =
        this.store.values.measurementSystem === "imperial"
          ? ["targetWeightLbs"]
          : ["targetWeightKg"]

      const allRequiredPresent = requiredFields.every((value) => {
        const formValue = formValues[value]

        if (Array.isArray(formValue)) return !!formValue.length

        return formValue && formValue !== 0
      })

      this.isSubmitAllowed = allRequiredPresent

      // Reset validation errors on units change to not confuse user
      // because we perform validation only on form submit
      formFields.forEach((field) => (this.errors[field] = null))
    },

    validateWeightRange() {
      // Check if user-entered value fits in required range
      // depending on BMI value
      this.minSuggestedWeightKg = this.getMinSuggestedWeightKg()

      this.minTargetWeighError = this.formValues.targetWeightKg < this.minSuggestedWeightKg

      // Ready for the max target weight validation.
      // Hardcoded to false according to adjusted product requirements.
      this.maxTargetWeighError = false

      this.isSubmitAllowed = !this.minTargetWeighError && !this.maxTargetWeighError

      this.errors["targetWeightLbs"] = this.minTargetWeighError || this.maxTargetWeighError
      this.errors["targetWeightKg"] = this.minTargetWeighError || this.maxTargetWeighError
    },

    resetErrors() {
      if (this.minTargetWeighError && this.maxTargetWeighError) return

      this.minTargetWeighError = false
      this.maxTargetWeighError = false
    },

    //
    // Suggested weight
    //

    isMetric() {
      return this.store.values.measurementSystem === "metric"
    },

    getBmi() {
      const { heightCm, heightFt, heightIn } = this.store.values
      const { weightKg, weightLbs } = this.formValues

      const values = {
        heightCm,
        heightFt,
        heightIn,
        weightKg,
        weightLbs,
      }

      return getBmi(values, this.isMetric())
    },

    getMinSuggestedWeightKg() {
      const minBMI = 18.5
      const normalizedHeight = getNormalizedHeight(this.store.values, this.isMetric())
      return Math.round(minBMI * (normalizedHeight / 100) ** 2)
    },

    getMinSuggestedWeightLbs() {
      return Math.round(kgToLbs(this.getMinSuggestedWeightKg()))
    },

    getMaxSuggestedWeightKg() {
      const minBMI = 18.5
      const maxBMI = 25
      const normalizedWeight = getNormalizedWeight(this.store.values, this.isMetric())
      const normalizedHeight = getNormalizedHeight(this.store.values, this.isMetric())
      const isHealthyWeight = this.bmi > minBMI && this.bmi < maxBMI

      if (isHealthyWeight) {
        return normalizedWeight - 1
      }

      return Math.round(maxBMI * (normalizedHeight / 100) ** 2)
    },

    getMaxSuggestedWeightLbs() {
      return Math.round(kgToLbs(this.getMaxSuggestedWeightKg()))
    },

    // Formula taken from the existing cppv3 o-series
    getSuggestedWeight() {
      const normalizedHeight = getNormalizedHeight(this.store.values, this.isMetric())

      let suggestedWeightKg = Math.round((normalizedHeight ** 2 * 21.1) / 10000)
      if (suggestedWeightKg < 30) {
        suggestedWeightKg = 30
      }

      const suggestedWeightLbs = Math.round(kgToLbs(suggestedWeightKg))

      return { suggestedWeightKg, suggestedWeightLbs }
    },
  })
