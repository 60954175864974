import { getBmi } from "../helpers"

const KGS_IN_LBS = 0.453592
const LBS_IN_KG = 2.20462

export const kgToLbs = (kg = 0) => Math.round((kg / KGS_IN_LBS) * 100) / 100
export const lbsToKg = (lbs = 0) => Math.round((lbs / LBS_IN_KG) * 100) / 100

export default (getView) =>
  ({ nextStepPath, formFields }) => ({
    bmi: null,
    bmiCategory: null,

    ...getView({
      nextStepPath,
      formFields,
    }),

    init() {
      this.commonInit()
      this.bmi = this.getBmi().toFixed(1)
      this.bmiCategory = this.getBmiCategory()
    },

    handleUnitsChange(units) {
      this.setMeasurementSystem(units)
      this.convertWeight()
      this.setSubmitAllowed(this.formValues, formFields)
    },

    handleWeightInput($event, field) {
      this.handleNumericInput($event, field)
      this.convertWeight()
      this.bmi = this.getBmi().toFixed(1)
      this.bmiCategory = this.getBmiCategory()
    },

    convertWeight() {
      if (this.isMetric()) {
        // Don't do anything if there is nothing to convert
        // to prevent invalid values to appear
        if (!this.formValues.weightKg && this.formValues.weightKg !== 0) return

        this.formValues.weightLbs = kgToLbs(this.formValues.weightKg)
      }

      if (this.isImperial()) {
        if (!this.formValues.weightLbs && this.formValues.weightLbs !== 0) return

        this.formValues.weightKg = lbsToKg(this.formValues.weightLbs)
      }
    },

    // Perform final convertions before submit
    processFormValues() {
      const { weightLbs, weightKg } = this.formValues

      if (this.isMetric()) {
        return {
          weightKg,
          weightLbs: kgToLbs(weightKg),
        }
      }

      if (this.isImperial()) {
        return {
          weightKg: lbsToKg(weightLbs),
          weightLbs,
        }
      }
    },

    setSubmitAllowed(formValues, formFields) {
      const requiredFields =
        this.store.values.measurementSystem === "imperial" ? ["weightLbs"] : ["weightKg"]

      const allRequiredPresent = requiredFields.every((value) => {
        const formValue = formValues[value]

        if (Array.isArray(formValue)) return !!formValue.length

        return formValue && formValue !== 0
      })

      this.isSubmitAllowed = allRequiredPresent

      // Reset validation errors on units change to not confuse user
      // because we perform validation only on form submit
      formFields.forEach((field) => (this.errors[field] = null))
    },

    //
    // BMI
    //

    isMetric() {
      return this.store.values.measurementSystem === "metric"
    },

    getBmi() {
      const { heightCm, heightFt, heightIn } = this.store.values
      const { weightKg, weightLbs } = this.formValues

      const values = {
        heightCm,
        heightFt,
        heightIn,
        weightKg,
        weightLbs,
      }

      return getBmi(values, this.isMetric())
    },

    getBmiCategory() {
      const BMI = this.getBmi()

      if (!BMI) return null

      if (BMI <= 18.5) {
        return "underweight"
      } else if (BMI >= 18.6 && BMI <= 24.9) {
        return "normal"
      } else if (BMI >= 25 && BMI <= 29.9) {
        return "overweight"
      } else if (BMI >= 30) {
        return "obese"
      }
    },
  })
